import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';

// Image
import videoImg from '../../../../assets/img/about/about-video-bg2.png';

// import videoImg from '../../../../../assets/img/about/about-video-bg2.png';

const CourseSidebar = () => {

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);

    return (
        <div className="inner-column">
            {/* <ModalVideo channel='youtube' isOpen={isOpen} videoId='YLN1Argi7ik' onClose={() => { openModal(); }} />
            <div className="intro-video media-icon orange-color2">
                <img className="video-img" src={videoImg} alt="Video Image" />
                <Link className="popup-videos" onClick={() => { openModal(); }} >
                    <i className="fa fa-play"></i>
                </Link>
                <h4>Preview this course</h4>
            </div> */}
            <div className="course-features-info adults-style">
                <ul>
                    <li className="lectures-feature">
                        <i className="fa fa-check-square-o"></i>
                        <span className="label">Sessions</span>
                        <span className="value">4 - 8</span>
                    </li>
                    
                    <li className="quizzes-feature">
                        <i className="fa fa-folder-open"></i>
                        <span className="label">Assignment</span>
                        <span className="value">0</span>
                    </li>
                    
                    <li className="duration-feature">
                        <i className="fa fa-clock-o"></i>
                        <span className="label">Duration</span>
                        <span className="value">4 - 8 weeks</span>
                    </li>
                    
                    <li className="students-feature">
                        <i className="fa fa-users"></i>
                        <span className="label">Class Ratio</span>
                        <span className="value">1:1</span>
                    </li>
                    
                    <li className="assessments-feature">
                        <i className="fa fa-certificate"></i>
                        <span className="label">Certificate</span>
                        <span className="value">Yes</span>
                    </li>

                    <li className="assessments-feature">
                        <i className="fa fa-language"></i>
                        <span className="label">Language</span>
                        <span className="value">Cantonese</span>
                    </li>
                </ul>
            </div>                
            <div className="btn-part">
                <a href="https://wa.me/85264123544" target="_blank" className="btn readon2 transparent adults-style">Chat Now</a>
            </div>
        </div>
    );
}

export default CourseSidebar;