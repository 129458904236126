import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const ApplicationPart = () => {
    let tab1 = "In Person Private Class",
    tab2 = "Online Private Class"
    
    const tabStyle = 'application-tabs tabs-box';

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>Find Your Best Fit</h4>
                    <div className="intro-info-tabs adults-style">
                        <Tabs>
                            <TabList className={tabStyle}>
                                <Tab>
                                    <button className="adults-style">{tab1}</button>
                                </Tab>
                                <Tab>
                                    <button className="adults-style">{tab2}</button>
                                </Tab>
                                {/* <Tab>
                                    <button className="adults-style">{tab3}</button>
                                </Tab> */}
                            </TabList>

                            <TabPanel>
                            <h6>{tab1}</h6>
                            <p>Our instructors are well-trained to teach 1:1 in the classroom, there are serveral locations at your most convenient.</p>
                            <div className="curriculum-index adults-style">
                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Introduction</h4>
                                        </div>
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">1 sessions in 2 hours</span>
                                        </div>

                                        <p>Best for who has no idea about Time & Space Complexity.</p>

                                        <div>HK$ 1,440 <span className="red">Save 10%</span></div>
                                        <div className="black bold strikethrough">HK$ 1,600</div>
                                    </div>
                                    {/* <div className="curriculum-row-item curriculum-row-right">
                                        <h5>Starts on Dec 3</h5>
                                        <div><span>Every Saturday</span></div>
                                        <div><span>16:00 - 18:00</span></div>
                                    </div> */}
                                    <br className="clear"/>
                                </div>

                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Module 1 - Basics</h4>
                                        </div>
                                        
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">4 sessions in 8 hours</span>
                                        </div>

                                        <p>Best for who has already learned Time & Space Complexity.</p>

                                        <div>HK$ 5,760 <span className="red">Save 10%</span></div>
                                        <div className="black bold strikethrough">HK$ 6,400</div>
                                    </div>

                                    {/* <div className="curriculum-row-item curriculum-row-right">
                                        <h5>Starts on Dec 3</h5>
                                        <div><span>Every Saturday</span></div>
                                        <div><span>16:00 - 18:00</span></div>
                                    </div> */}
                                    <br className="clear"/>
                                </div>

                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Module 2 - Intermediate</h4>
                                        </div>
                                        
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">4 sessions in 8 hours</span>
                                        </div>

                                        <p>Best for who can solve Easy Level Questions with no pressure.</p>

                                        <div>HK$ 5,760 <span className="red">Save 10%</span></div>
                                        <div className="black bold strikethrough">HK$ 6,400</div>
                                    </div>

                                    {/* <div className="curriculum-row-item curriculum-row-right">
                                        <h5>Starts on Dec 3</h5>
                                        <div><span>Every Saturday</span></div>
                                        <div><span>16:00 - 18:00</span></div>
                                    </div> */}
                                    <br className="clear"/>
                                </div>
                            </div> 
                            </TabPanel>

                            <TabPanel>
                            <h6>{tab2}</h6>
                            <p>We will customise your learning paths and map the right knowledge points centered to your learning goals, providing what you need to learn, at the right time.</p>
                            <div className="curriculum-index adults-style">
                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Introduction</h4>
                                        </div>
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">1 sessions in 2 hours</span>
                                        </div>

                                        <p>Best for who has no idea about Time & Space Complexity.</p>

                                        <div>HK$ 1,280 <span className="red">Save 20%</span></div>
                                        <div className="black bold strikethrough">HK$ 1,600</div>
                                    </div>
                                    {/* <div className="curriculum-row-item curriculum-row-right">
                                        <h5>Starts on Dec 3</h5>
                                        <div><span>Every Saturday</span></div>
                                        <div><span>16:00 - 18:00</span></div>
                                    </div> */}
                                    <br className="clear"/>
                                </div>

                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Module 1 - Basics</h4>
                                        </div>
                                        
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">4 sessions in 8 hours</span>
                                        </div>

                                        <p>Best for who has already learned Time & Space Complexity.</p>

                                        <div>HK$ 5,120 <span className="red">Save 20%</span></div>
                                        <div className="black bold strikethrough">HK$ 6,400</div>
                                    </div>

                                    {/* <div className="curriculum-row-item curriculum-row-right">
                                        <h5>Starts on Dec 3</h5>
                                        <div><span>Every Saturday</span></div>
                                        <div><span>16:00 - 18:00</span></div>
                                    </div> */}
                                    <br className="clear"/>
                                </div>

                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Module 2 - Intermediate</h4>
                                        </div>
                                        
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">4 sessions in 8 hours</span>
                                        </div>

                                        <p>Best for who can solve Easy Level Questions with no pressure.</p>

                                        <div>HK$ 5,120 <span className="red">Save 20%</span></div>
                                        <div className="black bold strikethrough">HK$ 6,400</div>
                                    </div>

                                    {/* <div className="curriculum-row-item curriculum-row-right">
                                        <h5>Starts on Dec 3</h5>
                                        <div><span>Every Saturday</span></div>
                                        <div><span>16:00 - 18:00</span></div>
                                    </div> */}
                                    <br className="clear"/>
                                </div>
                            </div> 
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplicationPart;