import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// Image
// import aboutImg from '../../assets/img/about/history.png';
import classroomImg from '../../assets/img/facilities/classroom_1.jpeg'
import tabImg1 from '../../assets/img/about/tab1.jpeg';
import tabImg2 from '../../assets/img/about/tab2.jpeg';
import tabImg3 from '../../assets/img/about/tab3.png';

const AboutTab = () => {

    let tab1 = "History",
        tab2 = "Mission & Vision",
        tab3 = "Course Analysis"
    const tabStyle = 'nav nav-tabs histort-part';

    return (
        <div className="rs-about style1 pt-100 pb-100 md-pt-70 md-pb-70">
            <div className="container">
                <Tabs>
                    <div className="row align-items-center">
                        <div className="col-lg-6 padding-0 md-pl-14 md-pr-14 md-mb-30 relative">
                            <div className="img-part pr-110">
                                <img 
                                    src={classroomImg}
                                    alt="About Image" 
                                />
                            </div>
                            <TabList className={tabStyle}>
                                <Tab>
                                    <button><span className="icon-part"><i className="flaticon-folder"></i></span>{tab1}</button>
                                </Tab>
                                <Tab>
                                    <button><span className="icon-part"><i className="flaticon-idea"></i></span>{tab2}</button>
                                </Tab>
                                <Tab>
                                    <button><span className="icon-part"><i className="flaticon-analysis"></i></span>{tab3}</button>
                                </Tab>
                            </TabList>
                        </div>
                        <div className="col-lg-6 pl-120 md-pl-14">
                            <TabPanel>
                                <div className="about-history">
                                    <div className="sec-title mb-24">
                                        <h2 className="title">Our History</h2>
                                        <div className="desc">Co-founded in 2022 by a group of technical professionals, ProClasshub aimed to provide practical learning courses to all ages learners with different background and skill sets. </div>
                                    </div>
                                    <div className="tab-img">
                                        <img 
                                            src={tabImg1}
                                            alt="Tab Image" 
                                        />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="about-mission">
                                    <div className="sec-title mb-24">
                                        <h2 className="title">PROCLASSHUB Mission</h2>
                                        <div className="desc">Our mission is to help students engage with our designed courses, manage the learning process and provide the knowledge that can help to grow in the career or life.</div>
                                    </div>
                                    <div className="tab-img">
                                        <img 
                                            src={tabImg2}
                                            alt="Tab Image" 
                                        />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="about-admin">
                                    <div className="sec-title mb-24">
                                        <h2 className="title">Course Analysis</h2>
                                        <div className="desc">We regularly review the designed course by the data-driven feedbacks to ensure our professional knowledge is applicable and standardize. We believe we deliver the best to our students.</div>
                                    </div>
                                    <div className="tab-img">
                                        <img 
                                            src={tabImg3}
                                            alt="Tab Image" 
                                        />
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                </Tabs>
            </div>
        </div>
    );
}

export default AboutTab;