import React from 'react';
import { Link } from 'react-router-dom';
import previewImage from '../../../../assets/img/courses/kids/scratch-for-kids.jpeg'

const OverviewPart = () => {

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>Scratch for Kids - Code for Beginners</h4>
                    <p>Scratch is one of the world’s largest and most popular coding communities for  which allows your children to create their own digital stories, animations, and games.</p>
                    <p>As a parent, you might be wondering whether there are coding resources available which can teach your children how to code whilst also being fun. If you’re interested in getting your children into coding, read on and see how easy it is to code on Scratch!</p>
                    
                    {/* <ul className="student-list">
                        <li>23,564 Total Students</li>
                        <li><span className="theme_color">4.5</span> <span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span><span className="fa fa-star"></span> (1254 Rating)</li>
                        <li>256 Reviews</li>
                    </ul> */}
                    <img src={previewImage} className='pt-20 pb-20'/>
                    <h3>What you’ll learn?</h3>
                    <ul className="review-list kids-style">
                        <li>Provide coding knowledge based on the fundamental concepts by creating variables, loops, functions, and different arguments.</li>    
                        <li>Build fun games with code incorporating sprite animation, musical composition, synthesized sounds, special effects and more.</li>
                        <li>Learn the basic skills on how to construct the game.</li>
                    </ul>
                    
                    {/*
                        <h3>Requirements</h3>
                        <ul className="review-list kids-style">
                            <li>Phasellus enim magna, varius et commodo ut, ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel, sagittis vel justo</li>
                            <li>Ultricies vitae velit. Ut nulla tellus, eleifend euismod pellentesque vel.</li>
                            <li>Phasellus enim magna, varius et commodo ut.</li>
                            <li>Varius et commodo ut, ultricies vitae velit. Ut nulla tellus.</li>
                            <li>Phasellus enim magna, varius et commodo ut.</li>
                        </ul> */
                    }
                </div>
            </div>
        </div>
    );
}

export default OverviewPart;