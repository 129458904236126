import React from 'react';
import { Link } from 'react-router-dom';

import defaultLogo from '../../../assets/img/logo/logo.png';
import defaultBG from '../../../assets/img/bg/footer-bg.png';

const FooterStyleTwo = (props) => {
    const { footerClass, footerTopClass, footerLogo, footerBG, footerColor } = props;

    const footerBGStyle = {
        backgroundImage: `url(${footerBG})`,
        backgroundColor: `url(${footerColor})`
    }
    const defaultBGStyle = {
        backgroundImage: `url(${defaultBG})`,
        backgroundColor: '#273c66'
    }


    return (
        <footer id="rs-footer" className={footerClass ? footerClass : 'rs-footer'} style={footerBG && footerColor ? footerBGStyle : defaultBGStyle}>
            <div className={footerTopClass ? footerTopClass : "footer-top"}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <h4 className="widget-title">Explore</h4>
                            <ul className="site-map">
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/course">Courses</Link></li>
                                <li><Link to="/contact">Become a tutor</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <h4 className="widget-title">Resources</h4>
                            <ul className="site-map">
                                <li><Link to="/contact">Become a tutor</Link></li>
                                <li><Link to="/course">Courses</Link></li>
                                <li><Link to="/faq">FAQ</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 footer-widget">
                            <h4 className="widget-title">Contact</h4>
                            <ul className="address-widget address2">
                                <li>
                                    <i className="flaticon-call"></i>
                                    <div className="desc">
                                        <a href="https://wa.me/85264123544" target="_blank">+852 6412 3544</a>
                                    </div>
                                </li>
                                <li>
                                    <i className="flaticon-email"></i>
                                    <div className="desc">
                                        <a href="mailto:info@proclasshub.com">info@proclasshub.com</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row y-middle">
                        <div className="col-lg-4 md-mb-20">
                            <div className="footer-logo">
                                <Link to="/" as="/">
                                    <img src={footerLogo ? footerLogo : defaultLogo} alt="Logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4 md-mb-20">
                            <div className="copyright text-center md-text-left">
                                <p>© 2022 PROCLASSHUB All Rights Reserved.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 text-right md-text-left">
                            <ul className="footer-social">
                                <li>
                                    <a href="https://www.facebook.com/people/PROCLASSHUB/100087673637613/" target="_blank">
                                        <i className="fa fa-facebook-f"></i>
                                    </a>
                                </li>
                                {/* <li>
                                    <Link to="#">
                                        <i className="fa fa-twitter"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        <i className="fa fa-pinterest"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#">
                                        <i className="fa fa-google-plus"></i>
                                    </Link>
                                </li> */}
                                <li>
                                    <a href="https://www.instagram.com/proclasshub/" target="_blank">
                                        <i className="fa fa-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default FooterStyleTwo;