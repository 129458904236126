import React, { useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

import iconCode from '../../../../assets/img/icon/icon-spotlight-code-purple.png'
import iconCode2x from '../../../../assets/img/icon/icon-spotlight-code-purple@2x.png'
import iconStar from '../../../../assets/img/icon/icon-spotlight-star-purple.png'
import iconStar2x from '../../../../assets/img/icon/icon-spotlight-star-purple@2x.png'
import iconProject from '../../../../assets/img/icon/icon-spotlight-projects-purple.png'
import iconProject2x from '../../../../assets/img/icon/icon-spotlight-projects-purple@2x.png'
import iconCert from '../../../../assets/img/icon/icon-spotlight-certificate-purple.png'
import iconCert2x from '../../../../assets/img/icon/icon-spotlight-certificate-purple@2x.png'



const LessonsPart = () => {
    return (
<div className="content white-bg pt-30">
    <div className="course-overview">
        <div className="inner-box">
            <div className="inner-box-list">
                <h4 className="pb-15">Prgramming from Basic to Advance</h4>
                <p>We teach the computer science fundamentals by coding puzzles and playgrounds with fun, which allows the kids to invent with their own idea and explore key concepts of computer science.</p>

                <div className="table-row">
                    <div className="table-row-item">
                        <img src={iconCode} srcSet={`${iconCode2x} 2x`} alt="Images"/>
                        <span className="icon-text pl-10">Code at own pace</span>
                    </div>

                    <div className="table-row-item">
                        <img src={iconProject} srcSet={`${iconProject2x} 2x`} alt="Images"/>
                        <span className="icon-text pl-10">Build variety projects</span>
                    </div>
                </div>  

                <div className="table-row pb-30">
                    <div className="table-row-item">
                        <img src={iconStar} srcSet={`${iconStar2x} 2x`} alt="Images"/>
                        <span className="icon-text pl-10">Complete 4-8 lessons</span>
                    </div>

                    <div className="table-row-item">
                        <img src={iconCert} srcSet={`${iconCert2x} 2x`} alt="Images"/>
                        <span className="icon-text pl-10">Earn Certificate</span>
                    </div>
                </div>     

                <div className="curriculum-index kids-style">
                    <div className="curriculum-index-chapter pt-20 pb-5 pl-20 pr-20">
                        <h4>Stage 1 - Scratch Basic</h4>
                        <div className="pb-5">
                            <i className="fa fa-clock-o"></i>
                            <span className="value pl-2">8 hours</span>
                        </div>
                        <p>In the beginning session, the students will explore the foundational puzzle coding and debugging techniques in a visual, kid-friendly way. It can build a solid foundation of Computer Science concepts like sequencing, pattern recognition, event-handling, loops, and conditional logic, all through play-based learning.</p>
                    </div>

                    <div className="curriculum-index-chapter pt-20 pb-5 pl-20 pr-20">
                        <h4>Stage 2 - Code with Scratch</h4>
                        <div className="pb-5">
                            <i className="fa fa-clock-o"></i>
                            <span className="value pl-2">8 hours</span>
                        </div>
                        <p>After learning the basis, we will proceed to start inventing the student's own original puzzle coding projects like animations and playable arcade games. It develops the coding confidence by building a variety of custom projects.</p>
                    </div>

                    <div className="curriculum-index-chapter pt-20 pb-1 pl-20 pr-20">
                        <h4>Stage 3 - Advance Programming</h4>
                        <div className="pb-5">
                            <i className="fa fa-clock-o"></i>
                            <span className="value pl-2">8 hours</span>
                        </div>
                        <p>We teach the interactive story-based lessons, and fun characters will motivate students to learn fundamental programming concepts and take their first big steps toward coding. Students will have explore more opportunity to learn and develop as the game or web developer in the future career.</p>
                    </div>

                    <div className="curriculum-index-chapter pt-20 pb-5 pl-20">
                        <h4>Stage 4 - Learning Assessment</h4>
                        <div className="pb-5">
                            <i className="fa fa-clock-o"></i>
                            <span className="value pl-2">8 hours</span>
                        </div>
                        <p>We will have a simple placement test to verify the student's knowledge learnt from the course. We will provide a certificate for all of the students and they are qualified to develop the mobile and website application with the tools of puzzle coding. </p>
                    </div>
                </div> 
            </div>  
        </div>
    </div>
            
            {/* <Accordion className="accordion-box" allowMultipleExpanded={true} preExpanded={"1"}>
                <AccordionItem className="accordion-lesson-item" uuid="1">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <button>Lesson 1</button>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body acc-content current">
                        <div className="content">
                            Answer
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-lesson-item" uuid="1">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <button>Lesson 2</button>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body acc-content current">
                        <div className="content">
                            Answer
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-lesson-item" uuid="1">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <button>Lesson 3</button>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body acc-content current">
                        <div className="content">
                            Answer
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>                                             */}
        </div>
    );
}

export default LessonsPart;