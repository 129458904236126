import React from 'react';
import About from './AboutSection';
import Brand from '../../components/Common/Brand';
import Courses from './CourseSection';
import Counter from './CounterSection';
import Service from './ServiceSection';
import ScrollToTop from '../../components/Common/ScrollTop';
import BannerStyleFive from '../../components/Banner/BannerStyleFive';
import SEO from "../../components/SEO/SEO"

const HomeEightMain = () => {
	return (
		<React.Fragment>
			<SEO 
				title="PROCLASSHUB - Certificates, Kids, & Professional Courses"
				description="Find the right course for you. Choose from many topics, skill levels, and languages. Start learning today and success in future."
				keywords="消費卷,IT,程式,軟件,工程,幼兒教育,scratch,STEM,小朋友,technology,course,class,lesson,programming,software,website,mobile,mobile app,online,ecommerce,tech,coding,engineer"
				url="https://proclasshub.com/"
				image="https://ik.imagekit.io/ztlzy5otbl/Screenshot_2022-11-03_at_17.35.29.png"
			/>
				{/* <title>PROCLASSHUB | Certificates, Kids, & Professional Courses </title>
				<meta name="title" content="PROCLASSHUB | Certificates, Kids, & Professional Courses"/>
				<meta name="description" content="Learn in Present, Success in Future"/>
				<meta name="keywords" content="消費卷,IT,程式,軟件,工程,幼兒教育,scratch,STEM,小朋友,technology,course,class,lesson,programming,software,website,mobile,mobile app,online,ecommerce,tech,IT,coding,engineer"/>

				<meta property="og:type" content="website"/>
				<meta property="og:url" content="https://proclasshub.com/"/>
				<meta property="og:title" content="PROCLASSHUB | Certificates, Kids, & Professional Courses"/>
				<meta property="og:description" content="Learn in Present, Success in Future"/>
				<meta property="og:image" content="https://ik.imagekit.io/ztlzy5otbl/Screenshot_2022-11-03_at_17.35.29.png?ik-sdk-version=javascript-1.4.3&updatedAt=1667496975529"/>

				<meta property="twitter:card" content="summary_large_image"/>
				<meta property="twitter:url" content="https://proclasshub.com/"/>
				<meta property="twitter:title" content="PROCLASSHUB | Certificates, Kids, & Professional Courses"/>
				<meta property="twitter:description" content="Learn in Present, Success in Future"/>
				<meta property="twitter:image" content="https://ik.imagekit.io/ztlzy5otbl/Screenshot_2022-11-03_at_17.35.29.png?ik-sdk-version=javascript-1.4.3&updatedAt=1667496975529"/>
  */}
			{/* Banner-start */}
			<BannerStyleFive />
			{/* Banner-start */}

			{/* About-start */}
			<About />
			{/* About-start */}

			{/* Service-start */}
			<Service />
			{/* Service-start */}

			{/* Course-start */}
			<Courses />
			{/* Course-start */}

			{/* Cta-start */}
			{/* <Cta /> */}
			{/* Cta-start */}

			{/* Facility-start */}
			{/* <Facility /> */}
			{/* Facility-start */}

			{/* Counter-start */}
			<Counter />
			{/* Counter-start */}

			{/* Team-start */}
			{/* <Team /> */}
			{/* Team-start */}

			{/* Publication-start */}
			{/* <Publications /> */}
			{/* Publication-start */}

			{/* Brand-start */}
			<Brand />
			{/* Brand-start */}

			{/* Newsletter-area-start */}
			{/* <NewsletterFour 
				sectionClass='rs-newsletter style6 modify bg5 pt-100 pb-100 md-pb-80 md-pt-74'
			/> */}
			{/* Newsletter-area-end */}

			{/* Testimonial-start */}
			{/* <Testimonial /> */}
			{/* Testimonial-start */}

			{/* Blog-start */}
			{/* <Blog /> */}
			{/* Blog-start */}

			{/* scrolltop-start */}
			<ScrollToTop />
			{/* scrolltop-end */}
		</React.Fragment>
	);
}

export default HomeEightMain;