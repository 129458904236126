import React from 'react';
import { Helmet } from 'react-helmet';
import OffWrap from '../../components/Layout/Header/OffWrap';
import Header from '../../components/Layout/Header/Header';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';
import SearchModal from '../../components/Layout/Header/SearchModal';
import ScrollToTop from '../../components/Common/ScrollTop';
// import Newsletter from '../../components/Common/Newsletter';
import ContactMain from './ContactMain';

// Image
import favIcon from '../../assets/img/fav.png';
import stickyLogo from '../../assets/img/logo/logo.png';
import darkLogo from '../../assets/img/logo/logo-dark2.png';
// import footerLogo from '../../assets/img/logo/lite-logo.png';


const Contact = () => {
    return (
        <React.Fragment>
            <OffWrap />
            {/* Header */}
            <Helmet>
                <link rel="icon" href={favIcon} />
            </Helmet>
            <Header
                parentMenu='contact'
                headerNormalLogo={darkLogo}
                headerStickyLogo={stickyLogo}
                CanvasLogo={darkLogo}
                mobileNormalLogo={darkLogo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style3 modify expand-none"
                TopBarClass="topbar-area dark-parimary-bg"
                TopBar='enable'
                emailAddress='info@proclasshub.com'
                phoneNumber='+852 6412 3544'
            />
            {/* Header */}

            {/* ContactMain Section Start */}
            <ContactMain />
            {/* ContactMain Section End */}

            {/* Newsletter Section Start */}
            {/* <Newsletter
                sectionClass="rs-newsletter style1 orange-color event-bg mb--90 sm-mb-0 sm-pb-80"
                titleClass="title mb-0 white-color"
            /> */}
            {/* Newsletter Section End */}

            {/* Footer */}
            <FooterStyleTwo 
                footerTopClass='footer-top no-gap'
            />
            {/* Footer */}

            {/* SearchModal */}
            <SearchModal />
            {/* SearchModal */}

            {/* scrolltop-start */}
            <ScrollToTop
                scrollClassName="scrollup orange-color"
            />
            {/* scrolltop-end */}
        </React.Fragment>

    );
}


export default Contact;