import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CourseSingleTwo from '../../components/Courses/CourseSingleTwo';

// Course courseImg
import courseImg1 from '../../assets/img/courses/kids/stem1.jpg';
import courseImg2 from '../../assets/img/courses/kids/scratch-for-kids.jpeg';

const CoursePart = (props) => {

    let tab1 = "All",
        tab2 = "Science",
        tab3 = "Business",
        tab4 = "Humanities",
        tab5 = "Diploma"
    const tabStyle = 'gridFilter text-center mb-50 md-mb-30';

    return (
        <div id="rs-popular-course" className="rs-popular-courses style1-kids pt-100 pb-100 md-pt-70 md-pb-50">
            <div className="container">
                <div className="title mb-30 md-mb-14">
                    <h3 className="text-part">For Kids</h3>
                </div>
                <Tabs>
                    {/* <TabList className={tabStyle}>
                        <Tab>
                            <button>{tab1}</button>
                        </Tab>
                        <Tab>
                            <button>{tab2}</button>
                        </Tab>
                        <Tab>
                            <button>{tab3}</button>
                        </Tab>
                        <Tab>
                            <button>{tab4}</button>
                        </Tab>
                        <Tab>
                            <button>{tab5}</button>
                        </Tab>
                    </TabList> */}

                    <TabPanel>
                        <div className="row ">
                            <div className="col-lg-4 col-md-6">
                                <CourseSingleTwo
                                    courseId={1000}
                                    courseType={props.type}
                                    courseClass="courses-item mb-30"
                                    courseImg={courseImg1}
                                    courseSessions={8}
                                    courseHours={16}
                                    courseTitle="Puzzle Programming - S.T.E.M course for Kids"
                                    courseCategory="S.T.E.M (Age 5 - 12)"
                                />
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <CourseSingleTwo
                                    courseId={1001}
                                    courseType={props.type}
                                    courseClass="courses-item mb-30"
                                    courseImg={courseImg2}
                                    courseSessions={8}
                                    courseHours={16}
                                    courseTitle="Scratch for Kids - Code for Beginners"
                                    courseCategory="Web Development (Age 4 - 8)"
                                />
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    );
}

export default CoursePart;