import React from 'react';
import { Route, BrowserRouter as Router, Switch, withRouter, Redirect } from 'react-router-dom';

//Custom Components


import HomeEight from '../pages/home-8';
import AboutTwo from '../pages/about-2';
import CourseTwo from '../pages/course-2';
import CourseSingle from '../pages/course-2/course-single';
import Faq from '../pages/faq';
import Contact from '../pages/contact';
import Error from '../pages/404';
import LoadTop from '../components/Common/ScrollTop/LoadTop'


const App = () => {
    return (
        <div className='App'>
            <Router>
                <LoadTop />
                <Switch>
                    <Route path="/" exact component={HomeEight} />
                    <Route path="/home" component={HomeEight} />
                    <Route path="/about" component={AboutTwo} />
                    <Route path="/course" exact>
                        <Redirect to="/course/adults" />
                    </Route>
                    <Route path="/course/:type" exact component={CourseTwo}/>
                    <Route path="/course/:type/:id" exact component={CourseSingle} />
                    <Route path="/faq" component={Faq} />
                    <Route path="/contact" component={Contact} />
                    <Route component={Error} />
                </Switch>
            </Router>

        </div>
    );
}

export default App;
