import React, { useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

import iconCode from '../../../../assets/img/icon/icon-spotlight-code-purple.png'
import iconCode2x from '../../../../assets/img/icon/icon-spotlight-code-purple@2x.png'
import iconStar from '../../../../assets/img/icon/icon-spotlight-star-purple.png'
import iconStar2x from '../../../../assets/img/icon/icon-spotlight-star-purple@2x.png'
import iconProject from '../../../../assets/img/icon/icon-spotlight-projects-purple.png'
import iconProject2x from '../../../../assets/img/icon/icon-spotlight-projects-purple@2x.png'
import iconCert from '../../../../assets/img/icon/icon-spotlight-certificate-purple.png'
import iconCert2x from '../../../../assets/img/icon/icon-spotlight-certificate-purple@2x.png'



const LessonsPart = () => {
    return (
<div className="content white-bg pt-30">
    <div className="course-overview">
        <div className="inner-box">
            <div className="inner-box-list">
                <h4 className="pb-15">Coding Fundamentals from Basic to Proficiency</h4>
                <p>We teach the computer science fundamentals by coding puzzles and playgrounds with fun, which allows the kids to invent with their own idea and explore key concepts of computer science.</p>

                <div className="table-row">
                    <div className="table-row-item">
                        <img src={iconCode} srcSet={`${iconCode2x} 2x`} alt="Images"/>
                        <span className="icon-text pl-10">Code at own pace</span>
                    </div>

                    <div className="table-row-item">
                        <img src={iconProject} srcSet={`${iconProject2x} 2x`} alt="Images"/>
                        <span className="icon-text pl-10">Build variety projects</span>
                    </div>
                </div>  

                <div className="table-row pb-30">
                    <div className="table-row-item">
                        <img src={iconStar} srcSet={`${iconStar2x} 2x`} alt="Images"/>
                        <span className="icon-text pl-10">Complete 6 - 12 lessons</span>
                    </div>

                    <div className="table-row-item">
                        <img src={iconCert} srcSet={`${iconCert2x} 2x`} alt="Images"/>
                        <span className="icon-text pl-10">Earn Certificate</span>
                    </div>
                </div>     

                <div className="curriculum-index kids-style">
                    <div className="curriculum-index-chapter pt-20 pb-5 pl-20 pr-20">
                        <h4>Stage 1 - Interative way to build robot</h4>
                        <div className="pb-5">
                            <i className="fa fa-clock-o"></i>
                            <span className="value pl-2">1.5 hours</span>
                        </div>
                        <p>In the beginning session, we will introduce the teaching materials and correct usage of the LEGO interactive parts and blocks. Students will have a basic concept to understand how to build a robot and controlled by the Stratch puzzle programming.</p>
                    </div>

                    <div className="curriculum-index-chapter pt-20 pb-5 pl-20 pr-20">
                        <h4>Stage 2 - Creating with Code</h4>
                        <div className="pb-5">
                            <i className="fa fa-clock-o"></i>
                            <span className="value pl-2">3 hours</span>
                        </div>
                        <p>After learning the basis, we will teach the student how to to control the built robots for the basic movements. We will introduce some basic programming concepts but with a more interactive way to inspire the student and raise their interest.</p>
                    </div>

                    <div className="curriculum-index-chapter pt-20 pb-1 pl-20 pr-20">
                        <h4>Stage 3 - Creativity Learning</h4>
                        <div className="pb-5">
                            <i className="fa fa-clock-o"></i>
                            <span className="value pl-2">3 hours</span>
                        </div>
                        <p>For our creativity tools of LEGO MindStorm and Spike Education tool sets, we teach the interactive story-based lessons, and fun characters will motivate students to learn fundamental programming concepts and take their first big steps toward coding. Students will have explore more opportunity to learn and develop programming in the future career.</p>
                    </div>

                    <div className="curriculum-index-chapter pt-20 pb-5 pl-20">
                        <h4>Stage 4 - Learning Assessment</h4>
                        <div className="pb-5">
                            <i className="fa fa-clock-o"></i>
                            <span className="value pl-2">1.5 hours</span>
                        </div>
                        <p>We will have a simple placement test to verify the student's knowledge learnt from the course. We will provide a certificate for all of the students and they are qualified to develop the mobile and website application with the tools of puzzle coding. </p>
                    </div>
                </div> 
            </div>  
        </div>
    </div>
            
            {/* <Accordion className="accordion-box" allowMultipleExpanded={true} preExpanded={"1"}>
                <AccordionItem className="accordion-lesson-item" uuid="1">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <button>Lesson 1</button>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body acc-content current">
                        <div className="content">
                            Answer
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-lesson-item" uuid="1">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <button>Lesson 2</button>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body acc-content current">
                        <div className="content">
                            Answer
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-lesson-item" uuid="1">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <button>Lesson 3</button>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body acc-content current">
                        <div className="content">
                            Answer
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>                                             */}
        </div>
    );
}

export default LessonsPart;