import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const ApplicationPart = () => {
    let tab1 = "Online Bootcamp",
    tab2 = "Online Private Class"
    
    const tabStyle = 'application-tabs tabs-box';

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>Find Your Best Fit</h4>
                    <div className="intro-info-tabs adults-style">
                        <Tabs>
                            <TabList className={tabStyle}>
                                <Tab>
                                    <button className="adults-style">{tab1}</button>
                                </Tab>
                                <Tab>
                                    <button className="adults-style">{tab2}</button>
                                </Tab>
                                {/* <Tab>
                                    <button className="adults-style">{tab3}</button>
                                </Tab> */}
                            </TabList>

                            <TabPanel>
                            <h6>{tab1}</h6>
                            <p>Our instructors are well-trained to teach over live video chat, just as effective as in the classroom, allow you to learn from anywhere.</p>
                            <div className="curriculum-index adults-style">
                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Module 1 - Basics</h4>
                                        </div>
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">4 sessions in 8 hours</span>
                                        </div>

                                        <p>Best for who has no programming experience and We'll teach everything you need to know.</p>

                                        <div>HK$ 3,320 <span className="red">Save 50%</span></div>
                                        <div className="black bold strikethrough">HK$ 6,640</div>
                                    </div>
                                    <div className="curriculum-row-item curriculum-row-right">
                                        <h5 className='strikethrough'>Starts on Dec 3</h5>
                                        <div><span className='strikethrough'>Every Saturday</span></div>
                                        <div><span className='strikethrough'>16:00 - 18:00</span></div>
                                        <div><span className="red">FULL</span></div>
                                    </div>
                                    <br className="clear"/>
                                </div>

                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Module 2 - Intermediate</h4>
                                        </div>
                                        
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">4 sessions in 8 hours</span>
                                        </div>

                                        <p>Best for who has already learned Swift and UIKit basic knowledge, moving on to intermediate level.</p>

                                        <div>HK$ 3,320 <span className="red">Save 50%</span></div>
                                        <div className="black bold strikethrough">HK$ 6,640</div>
                                    </div>

                                    <div className="curriculum-row-item curriculum-row-right">
                                        <h5 className='strikethrough'>Starts on Dec 3</h5>
                                        <div><span className='strikethrough'>Every Saturday</span></div>
                                        <div><span className='strikethrough'>16:00 - 18:00</span></div>
                                        <div><span className="red">FULL</span></div>
                                    </div>
                                    <br className="clear"/>
                                </div>

                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-1 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Module 3 - Advanced</h4>
                                        </div>

                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">4 sessions in 8 hours</span>
                                        </div>

                                        <p>You'd expect to learn how to build end to end mobile applications and gain a well-rounded technical foundation.</p>

                                        <div>HK$ 3,320 <span className="red">Save 50%</span></div>
                                        <div className="black bold strikethrough">HK$ 6,640</div>
                                    </div>

                                    <div className="curriculum-row-item curriculum-row-right">
                                        <h5 className='strikethrough'>Starts on Dec 3</h5>
                                        <div><span className='strikethrough'>Every Saturday</span></div>
                                        <div><span className='strikethrough'>16:00 - 18:00</span></div>
                                        <div><span className="red">FULL</span></div>
                                    </div>
                                    <br className="clear"/>
                                </div>
                            </div> 
                            </TabPanel>

                            <TabPanel>
                            <h6>{tab2}</h6>
                            <p>We will customise your learning paths and map the right knowledge points centered to your learning goals, providing what you need to learn, at the right time.</p>
                            <div className="curriculum-index adults-style">
                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Module 1 - Basics</h4>
                                        </div>
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">4 sessions in 8 hours</span>
                                        </div>

                                        <p>Best for who has no programming experience and We'll teach everything you need to know.</p>

                                        <div>HK$ 4,980 <span className="red">Save 15%</span></div>
                                        <div className="black bold strikethrough">HK$ 6,640</div>
                                    </div>
                                    {/* <div className="curriculum-row-right">
                                        <h5>Starts on Dec 3</h5>
                                        <div><span>Every Saturday</span></div>
                                        <div><span>16:00 - 18:00</span></div>
                                    </div> */}
                                    <br className="clear"/>
                                </div>

                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Module 2 - Intermediate</h4>
                                        </div>
                                        
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">4 sessions in 8 hours</span>
                                        </div>

                                        <p>Best for who has already learned Swift and UIKit basic knowledge, moving on to intermediate level.</p>

                                        <div>HK$ 4,980 <span className="red">Save 15%</span></div>
                                        <div className="black bold strikethrough">HK$ 6,640</div>
                                    </div>

                                    {/* <div className="curriculum-row-right">
                                        <h5>Arrange with your tutor</h5>
                                        <div><span>Mon - Sun</span></div>
                                    </div> */}
                                    <br className="clear"/>
                                </div>

                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-1 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Module 3 - Advanced</h4>
                                        </div>

                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">4 sessions in 8 hours</span>
                                        </div>

                                        <p>You'd expect to learn how to build end to end mobile applications and gain a well-rounded technical foundation.</p>

                                        <div>HK$ 4,980 <span className="red">Save 15%</span></div>
                                        <div className="black bold strikethrough">HK$ 6,640</div>
                                    </div>

                                    {/* <div className="curriculum-row-right">
                                        <h5>Starts on Dec 3</h5>
                                        <div><span>Every Saturday</span></div>
                                        <div><span>16:00 - 18:00</span></div>
                                    </div> */}
                                    <br className="clear"/>
                                </div>
                            </div>
                            {/* <div display="grid">
                                    <table className="application-table adults-style">
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <h6>Module 1</h6>
                                                </td>
                                                <td>
                                                    <h6>Module 1 - 2</h6>
                                                </td>
                                                <td>
                                                    <h6>Full Course : Module 1 - 5</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="black bold">Course</td>
                                                <td>
                                                    <p>Web Programming with Python Basics</p>
                                                </td>
                                                <td>
                                                    <p>Web Programming Essentials</p>
                                                </td>
                                                <td>
                                                    <p>Advanced Full Stack Web Development</p>
                                                </td>
                                            </tr>
                                            <tr className="linebreak">
                                                <td className="black bold">Length</td>
                                                <td>8 sessions in 16 hrs</td>
                                                <td>16 sessions in 32 hrs</td>
                                                <td>40 sessions in 80 hrs</td>
                                            </tr>
                                            <tr className="linebreak">
                                                <td className="black bold">Best For</td>
                                                <td>Starter module with no commitment. Take the leap and enter the vast world of Web Development.</td>
                                                <td>Ready to become a full-stack Web Developer? Boost your career with Python in Django and data management knowledge.</td>
                                                <td>Gain a well-rounded technical foundation, and walk away with a portfolio showcasing your web development acumen.</td>
                                            </tr>
                                            <tr className="linebreak">
                                                <td className="black bold">Price</td>
                                                <td>
                                                    <div className="black bold">HK$ 12,800</div>
                                                    <div>
                                                        <div>HK$ 800/hour</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="black bold">HK$ 24,320</div>
                                                    <div>
                                                        <div>HK$760/hour <span className="red">(5% Off)</span></div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="black bold">HK$ 57,600</div>
                                                    <div>
                                                        <div>HK$720/hour <span className="red">(10% Off)</span></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> */}
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplicationPart;