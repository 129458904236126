import React from 'react';
import CourseDetailsPart from './CourseDetailsPart';
import ScrollToTop from '../../../../components/Common/ScrollTop';
import SEO from '../../../../components/SEO/SEO'

const CourseDetailsMain = (props) => {

    return (
        <React.Fragment>
            <SEO 
				title={`Newbie for Kids programming course - Scratch`}
				description={`Explore and enact new possibility for kids by Scratch, the MIT based education tool`}
				keywords="消費卷,IT,程式,軟件,工程,幼兒教育,scratch,STEM,小朋友,blockly,technology,course,class,lesson,programming,software,website,mobile,mobile app,online,ecommerce,tech,coding,engineer"
				url={`https://proclasshub.com/course/kids/1001`}
				image="https://proclasshub.com/static/media/scratch-for-kids.6d526f38.jpeg"
			/>

            {/* CourseDetails Start */}
            <CourseDetailsPart/>
            {/* CourseDetails End */}

			{/* scrolltop-start */}
			<ScrollToTop
				scrollClassName="scrollup orange-color"
			/>
			{/* scrolltop-end */}

        </React.Fragment>
    )
}

export default CourseDetailsMain;