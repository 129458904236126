import React from 'react';
import CourseAdults from './CourseSectionAdults';
import CourseKids from './CourseSectionKids';
import ScrollToTop from '../../components/Common/ScrollTop';
import SEO from "../../components/SEO/SEO"

const CourseMain = (props) => {
    return (
        <React.Fragment>
            <SEO 
				title={`Courses For ${props.type == "kids" ? "Kids" : "Adults"}`}
				description="Our courses are designed by programming experts. Classes are taught by highly trained and experienced teachers who bring energy and expertise to every class."
				keywords="消費卷,IT,程式,軟件,工程,幼兒教育,scratch,STEM,小朋友,technology,course,class,lesson,programming,software,website,mobile,mobile app,online,ecommerce,tech,coding,engineer"
				url={`https://proclasshub.com/course/${props.type}`}
				image="https://ik.imagekit.io/ztlzy5otbl/Screenshot_2022-11-03_at_17.35.29.png"
			/>
            {/* CoursePart Start */}
            {
                props.type == "kids" ? <CourseKids type={props.type}/> : <CourseAdults type={props.type}/>
            }
            {/* CoursePart End */}

			{/* scrolltop-start */}
			<ScrollToTop
				scrollClassName="scrollup"
			/>
			{/* scrolltop-end */}

        </React.Fragment>
    )
}

export default CourseMain;