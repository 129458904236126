import React from 'react';
import Slider from "react-slick";

import facebookLogo from '../../../assets/img/brand/facebook_logo.png'
// import weworkLogo from '../../../assets/img/brand/wework_logo.png'
import theDeskLogo from '../../../assets/img/brand/theDesk_logo.png'
import tqukLogo from '../../../assets/img/brand/tquk_logo.png'
import zoomLogo from '../../../assets/img/brand/zoom_logo.png'
import instagramLogo from '../../../assets/img/brand/instagram_logo.png'
// import brandNormalLogo2 from '../../../assets/img/brand/2.png';
// import brandNormalLogo3 from '../../../assets/img/brand/3.png';
// import brandNormalLogo4 from '../../../assets/img/brand/4.png';
// import brandNormalLogo5 from '../../../assets/img/brand/5.png';
// import brandNormalLogo6 from '../../../assets/img/brand/6.png';

const Brand = (props) => {
    const { brandClass } = props;

    const sliderSettings = {
        dots: false,
        centerMode: false,
        infinite: true,
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };

    return (
        <div className={brandClass ? brandClass : 'rs-partner pt-50 pb-50 md-pt-70 md-pb-70 sec-bg'}>
            <p className="brand-title">PARTNERS WITH</p>
            <div className="container">
                <Slider {...sliderSettings}>

                    <div className="partner-item">
                        <div className="logo-img">
                            <a href="#">
                                <img className="main-logo" src={tqukLogo} alt="" />
                            </a>
                        </div>
                    </div>

                    <div className="partner-item">
                        <div className="logo-img">
                            <a href="#">
                                <img className="main-logo" src={facebookLogo} alt="" />
                            </a>
                        </div>
                    </div>

                    <div className="partner-item">
                        <div className="logo-img">
                            <a href="#">
                                <img className="main-logo" src={theDeskLogo} alt="" />
                            </a>
                        </div>
                    </div>

                    <div className="partner-item">
                        <div className="logo-img">
                            <a href="#">
                                <img className="main-logo" src={zoomLogo} alt="" />
                            </a>
                        </div>
                    </div>

                    <div className="partner-item">
                        <div className="logo-img">
                            <a href="#">
                                <img className="main-logo" src={instagramLogo} alt="" />
                            </a>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    );
}

export default Brand;