import React from 'react';
import Header from '../../components/Layout/Header/Header';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';
// import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
// import Newsletter from '../../components/Common/Newsletter';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import AboutMain from './AboutMain';

// Image
import stickyLogo from '../../assets/img/logo/logo.png';
import darkLogo from '../../assets/img/logo/logo-dark2.png';
import SEO from '../../components/SEO/SEO'

const AboutTwo = () => {

    return (
        <React.Fragment>
            <SEO 
				title="About PROCLASSHUB"
				description="We are a group of people with the utmost enthusiasm to pass useful knowledge to all learners"
				keywords="消費卷,IT,程式,軟件,工程,幼兒教育,scratch,STEM,小朋友,technology,course,class,lesson,programming,software,website,mobile,mobile app,online,ecommerce,tech,coding,engineer"
				url="https://proclasshub.com/about"
				image="https://ik.imagekit.io/ztlzy5otbl/Screenshot_2022-11-03_at_17.35.29.png"
			/>
            <OffWrap />
            <Header
                parentMenu='about'
                headerNormalLogo={darkLogo}
                headerStickyLogo={stickyLogo}
                CanvasLogo={darkLogo}
                mobileNormalLogo={darkLogo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style3 modify expand-none"
                TopBarClass="topbar-area dark-parimary-bg"
                TopBar='enable'
                emailAddress='info@proclasshub.com'
                phoneNumber='+852 6412 3544'
            />

            {/* breadcrumb-area-start */}
            {/* <SiteBreadcrumb
                pageTitle="About"
                pageName="About Us"
                breadcrumbsImg={bannerbg}
            /> */}
            {/* breadcrumb-area-start */}

            {/* About Main */}
            <AboutMain />
            {/* About Main */}

            {/* <Newsletter
                sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70"
                titleClass="title mb-0 white-color"
            /> */}

            <FooterStyleTwo 
                footerTopClass='footer-top no-gap'
            />
            <SearchModal />
        </React.Fragment>
    );
}

export default AboutTwo;