import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const ApplicationPart = () => {
    let tab1 = "Online Private Class",
    tab2 = "In-person Private Class"

    const tabStyle = 'application-tabs tabs-box';

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>Find Your Best Fit</h4>
                    <div className="intro-info-tabs kids-style">
                        <Tabs>
                            <TabList className={tabStyle}>
                                <Tab>
                                    <button className="kids-style">{tab1}</button>
                                </Tab>
                                <Tab>
                                    <button className="kids-style">{tab2}</button>
                                </Tab>
                                {/* <Tab>
                                    <button className="adults-style">{tab3}</button>
                                </Tab> */}
                            </TabList>

                            <TabPanel>
                            <h6>{tab1}</h6>
                            <p>We have the online class which allows our instructor and student to teach and learn the best face to face experience.</p>
                            <div className="curriculum-index kids-style">
                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Beginner Module</h4>
                                        </div>
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">1.5 hour for each lesson, totally 6 lessons</span>
                                        </div>

                                        <p>Under the promotion period, it costs $250 HKD for 1 session and each lession requires 2 sessions. We will go through the basic experience for the beginners and demonstrate in all sessions.</p>

                                        <div>HK$ 3,000 <span className="red">Save 50%</span></div>
                                        <div className="black bold strikethrough">HK$ 6,000</div>
                                    </div>
                                    <div className="curriculum-row-item curriculum-row-right">
                                        <h5>Starts on July 15, 2023</h5>
                                        <div><span>Every Saturday</span></div>
                                        <div><span>11:00 - 12:30</span></div>
                                        <div><span>14:00 - 15:30</span></div>
                                    </div>
                                    <br className="clear"/>
                                </div>

                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Comphensive Module</h4>
                                        </div>
                                        
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">1.5 hour for each lesson, totally 12 lessons</span>
                                        </div>

                                        <p>Under the promotion period, it costs $250 HKD for 1 session and each lession requires 2 sessions. A full comphensive course with all the modules will be go through and demonstrate in the experience.</p>

                                        <div>HK$ 6,000 <span className="red">Save 50%</span></div>
                                        <div className="black bold strikethrough">HK$ 12,000</div>
                                    </div>

                                    <div className="curriculum-row-item curriculum-row-right">
                                        <h5>Starts on July 15, 2023</h5>
                                        <div><span>Every Saturday</span></div>
                                        <div><span>11:00 - 12:30</span></div>
                                        <div><span>14:00 - 15:30</span></div>
                                    </div>
                                    <br className="clear"/>
                                </div>
                            </div> 
                            </TabPanel>

                            <TabPanel>
                            <h6>{tab2}</h6>
                            <p>We have the private class which allows our instructor and student to teach and learn the best in 1:1 face to face experience.</p>
                            <div className="curriculum-index kids-style">
                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Beginner Module</h4>
                                        </div>
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">1.5 hour for each lesson, totally 6 lessons</span>
                                        </div>

                                        <p>Under the promotion period, it costs $500 HKD for 1 session and each lession requires 2 sessions. We will go through the basic experience for the beginners and demonstrate in all sessions.</p>

                                        <div>HK$ 6,000 <span className="red">Save 50%</span></div>
                                        <div className="black bold strikethrough">HK$ 12,000</div>
                                    </div>
                                    <div className="curriculum-row-item curriculum-row-right">
                                        <h5>Starts on July 15, 2023</h5>
                                        <div><span>Every Saturday</span></div>
                                        <div><span>11:00 - 12:30</span></div>
                                        <div><span>14:00 - 15:30</span></div>
                                    </div>
                                    <br className="clear"/>
                                </div>

                                <div className="curriculum-row curriculum-index-chapter pt-20 pb-5 pl-20">
                                    <div className="curriculum-row-item curriculum-row-left">
                                        <div>
                                            <h4>Comphensive Module</h4>
                                        </div>
                                        
                                        <div className="duration pb-10">
                                            <i className="fa fa-clock-o"></i>
                                            <span className="value pl-2">1.5 hour for each lesson, totally 12 lessons</span>
                                        </div>

                                        <p>Under the promotion period, it costs $500 HKD for 1 session and each lession requires 2 sessions. A full comphensive course with all the modules will be go through and demonstrate in the experience.</p>

                                        <div>HK$ 12,000 <span className="red">Save 50%</span></div>
                                        <div className="black bold strikethrough">HK$ 24,000</div>
                                    </div>

                                    <div className="curriculum-row-item curriculum-row-right">
                                        <h5>Starts on July 15, 2023</h5>
                                        <div><span>Every Saturday</span></div>
                                        <div><span>11:00 - 12:30</span></div>
                                        <div><span>14:00 - 15:30</span></div>
                                    </div>
                                    <br className="clear"/>
                                </div>
                            </div> 
                            </TabPanel>


                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplicationPart;