import React, { useState } from 'react';

import iconBeginner from '../../../../assets/img/icon/icon-spotlight-lvl-advanced-paleblue.png'
import iconBeginner2x from '../../../../assets/img/icon/icon-spotlight-lvl-advanced-paleblue@2x.png'

import iconOnline from '../../../../assets/img/icon/icon-spotlight-online-paleblue.png'
import iconOnline2x from '../../../../assets/img/icon/icon-spotlight-online-paleblue@2x.png'

import iconCode from '../../../../assets/img/icon/icon-spotlight-code-paleblue.png'
import iconCode2x from '../../../../assets/img/icon/icon-spotlight-code-paleblue@2x.png'
import iconStar from '../../../../assets/img/icon/icon-spotlight-star-paleblue.png'
import iconStar2x from '../../../../assets/img/icon/icon-spotlight-star-paleblue@2x.png'
import iconProject from '../../../../assets/img/icon/icon-spotlight-projects-paleblue.png'
import iconProject2x from '../../../../assets/img/icon/icon-spotlight-projects-paleblue@2x.png'
import iconCert from '../../../../assets/img/icon/icon-spotlight-certificate-paleblue.png'
import iconCert2x from '../../../../assets/img/icon/icon-spotlight-certificate-paleblue@2x.png'



const LessonsPart = () => {
    return (
<div className="content white-bg pt-30">
    <div className="course-overview">
        <div className="inner-box">
            <div className="inner-box-list">
                <h4 className="pb-15">Coding from Basic to Advanced</h4>
                <p>We have 3 modules for learners from entry level to advanced level as your flexibility! We also encourage learner who has no programming experience but passionate to become developer.</p>

                <div className="table-row">
                    <div className="table-row-item">
                        <img src={iconBeginner} srcSet={`${iconBeginner2x} 2x`} alt="Images"/>
                        <span className="icon-text pl-10">Beginner Level</span>
                    </div>

                    <div className="table-row-item">
                        <img src={iconOnline} srcSet={`${iconOnline2x} 2x`} alt="Images"/>
                        <span className="icon-text pl-10">100% Online</span>
                    </div>
                </div> 

                <div className="table-row">
                    <div className="table-row-item">
                        <img src={iconCode} srcSet={`${iconCode2x} 2x`} alt="Images"/>
                        <span className="icon-text pl-10">Code at own pace</span>
                    </div>

                    <div className="table-row-item">
                        <img src={iconProject} srcSet={`${iconProject2x} 2x`} alt="Images"/>
                        <span className="icon-text pl-10">Build variety projects</span>
                    </div>
                </div>  

                <div className="table-row pb-30">
                    <div className="table-row-item">
                        <img src={iconStar} srcSet={`${iconStar2x} 2x`} alt="Images"/>
                        <span className="icon-text pl-10">Complete in 12 weeks</span>
                    </div>

                    <div className="table-row-item">
                        <img src={iconCert} srcSet={`${iconCert2x} 2x`} alt="Images"/>
                        <span className="icon-text pl-10">Earn Certificate</span>
                    </div>
                </div>     

                <div className="curriculum-index adults-style">
                    <div className="curriculum-index-chapter pt-20 pb-5 pl-20">
                        <div className="pb-5">
                            <h4>Introduction and Setup</h4>
                        </div>
                        <p>Xcode installation and equipment check</p>
                        <div className="duration pb-5">
                            <i className="fa fa-clock-o"></i>
                            <span className="value pl-2">30 mins</span>
                        </div>
                    </div>

                    <div className="curriculum-index-chapter pt-20 pb-5 pl-20">
                        <div className="pb-5">
                            <h4>Module 1 - Basics</h4>
                        </div>
                        <p>Beginning with iOS Development and Swift 5</p>
                        <p>Xcode Interface Builder Walk Through and Design</p>
                        <p>Swift Programming Basics</p>
                        <p>Auto Layout and Responsive UIs</p>
                        <div className="duration pb-5">
                            <i className="fa fa-clock-o"></i>
                            <span className="value pl-2">4 sessions in 8 hours</span>
                        </div>
                    </div>

                    <div className="curriculum-index-chapter pt-20 pb-5 pl-20">
                        <div className="pb-5">
                            <h4>Module 2 - Intermediate</h4>
                        </div>
                        <p>Swift Programming - Control Flow and Optionals</p>
                        <p>iOS App Design Patterns and Code Structuring</p>
                        <p>Advanced Swift Programming - Classes, Inheritance</p>
                        <div className="duration pb-5">
                            <i className="fa fa-clock-o"></i>
                            <span className="value pl-2">4 sessions in 8 hours</span>
                        </div>
                    </div>

                    <div className="curriculum-index-chapter pt-20 pb-1 pl-20">
                        <div className="pb-5">
                            <h4>Module 3 - Advanced</h4>
                        </div>
                        <p>Git and Version Control</p>
                        <p>Networking, JSON Parsing, APIs and CollectionView</p>
                        <p>Command Line and Terminal - Cocoapods</p>
                        <p>Local Data Persistance - User Defaults and Realm</p>
                        <div className="duration pb-5">
                            <i className="fa fa-clock-o"></i>
                            <span className="value pl-2">4 sessions in 8 hours</span>
                        </div>
                    </div>
                </div> 
            </div>  
        </div>
    </div>
            
            {/* <Accordion className="accordion-box" allowMultipleExpanded={true} preExpanded={"1"}>
                <AccordionItem className="accordion-lesson-item" uuid="1">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <button>Lesson 1</button>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body acc-content current">
                        <div className="content">
                            Answer
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-lesson-item" uuid="1">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <button>Lesson 2</button>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body acc-content current">
                        <div className="content">
                            Answer
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="accordion-lesson-item" uuid="1">
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <button>Lesson 3</button>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body acc-content current">
                        <div className="content">
                            Answer
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>                                             */}
        </div>
    );
}

export default LessonsPart;