import React from 'react';
import CourseDetailsPart from './CourseDetailsPart';
import ScrollToTop from '../../../../components/Common/ScrollTop';
import SEO from '../../../../components/SEO/SEO'

const CourseDetailsMain = (props) => {

    return (
        <React.Fragment>
            <SEO 
				title="iOS & Swift - The Complete iOS App Development Bootcamp"
				description="From Beginner to iOS App Developer with Just One Course!"
				keywords="消費卷,IT,程式,軟件,工程,幼兒教育,scratch,STEM,小朋友,technology,course,class,lesson,programming,software,website,mobile,mobile app,online,ecommerce,tech,coding,engineer"
				url="https://proclasshub.com/course/adults/2000"
				image="https://proclasshub.com/static/media/ios_15.99422ce8.jpeg"
			/>
            {/* CourseDetails Start */}
            <CourseDetailsPart/>
            {/* CourseDetails End */}

			{/* scrolltop-start */}
			<ScrollToTop
				scrollClassName="scrollup orange-color"
			/>
			{/* scrolltop-end */}

        </React.Fragment>
    )
}

export default CourseDetailsMain;