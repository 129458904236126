import React from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CourseSingleTwo from '../../components/Courses/CourseSingleTwo';

// Course courseImg
import courseiOS from '../../assets/img/courses/adults/ios_15.jpeg';
import courseAndroid from '../../assets/img/courses/adults/course_android.jpeg';
import courseAlgorithm from '../../assets/img/courses/adults/algorithm_course.jpg'
import courseSystemDesign from '../../assets/img/courses/adults/system_design_course.png'

const CoursePart = (props) => {

    let tab1 = "All",
        tab2 = "Science",
        tab3 = "Business",
        tab4 = "Humanities",
        tab5 = "Diploma"
    const tabStyle = 'gridFilter text-center mb-50 md-mb-30';

    return (
        <div id="rs-popular-course" className="rs-popular-courses style1-adults pt-100 pb-100 md-pt-70 md-pb-50">
            <div className="container">
                <div className="title mb-30 md-mb-14">
                    <h3 className="text-part">For Adults</h3>
                </div>
                <Tabs>
                    {/* <TabList className={tabStyle}>
                        <Tab>
                            <button>{tab1}</button>
                        </Tab>
                        <Tab>
                            <button>{tab2}</button>
                        </Tab>
                        <Tab>
                            <button>{tab3}</button>
                        </Tab>
                        <Tab>
                            <button>{tab4}</button>
                        </Tab>
                        <Tab>
                            <button>{tab5}</button>
                        </Tab>
                    </TabList> */}

                    <TabPanel>
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <CourseSingleTwo
                                    courseId={2000}
                                    courseType={props.type}
                                    courseClass="courses-item mb-30"
                                    courseImg={courseiOS}
                                    courseSessions={4}
                                    courseHours={8}
                                    courseCategory="Mobile App Development"
                                    courseTitle="iOS & Swift - The Complete iOS App Development Bootcamp"
                                />
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <CourseSingleTwo
                                    courseId={2001}
                                    courseType={props.type}
                                    courseClass="courses-item mb-30"
                                    courseImg={courseAndroid}
                                    courseSessions={4}
                                    courseHours={8}
                                    courseCategory="Mobile App Development"
                                    courseTitle="The Complete Android & Kotlin Development Masterclass"
                                />
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <CourseSingleTwo
                                    courseId={2002}
                                    courseType={props.type}
                                    courseClass="courses-item mb-30"
                                    courseImg={courseAlgorithm}
                                    courseSessions={4}
                                    courseHours={8}
                                    courseCategory="Interview / Data Structure & Algorithm"
                                    courseTitle="Cracking Coding Interview : Data Structure & Algorithm"
                                />
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <CourseSingleTwo
                                    courseId={2003}
                                    courseType={props.type}
                                    courseClass="courses-item mb-30"
                                    courseImg={courseSystemDesign}
                                    courseSessions={4}
                                    courseHours={8}
                                    courseCategory="Interview / System Design"
                                    courseTitle="Cracking System Design Interview - Software Architecture"
                                />
                            </div>

                        </div>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    );
}

export default CoursePart;