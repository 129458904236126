import React from 'react';
import { Link } from 'react-router-dom';
import previewImage from '../../../../assets/img/courses/adults/system_design.png'

const OverviewPart = () => {

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>Cracking System Design Interview - Software Architecture</h4>
                    <p>If you have a System Design interview coming up and want a quick crash course</p>
                    <p>If you are designing a new system at work, and want to make sure you get it right</p>
                    <p>If you want to know how some FAANGs and other companies have built their systems</p>

                    <img src={previewImage} className='pt-20 pb-20'/>
                    <h3>What you’ll learn?</h3>
                    <ul className="review-list adults-style">
                        <li>Solutions to the most common Interview Questions at FAANGs</li>
                        <li>Tips and tricks to crack your next System Design Interview</li>
                        <li>Learn how the biggest systems out there are Architected</li>
                        <li>Well explained the System Design from top down</li>
                        <li>Ace the coding interview at the top tech companies (Google, Amazon, Facebook, Apple, Microsoft, Uber, Deliveroo, PayPal + others)</li>
                    </ul>

                    <br/>

                    <h3>Requirements</h3>
                    <ul className="review-list adults-style">
                        <li>Basic knowledge of Computer science, but this course is ideally meant for people who are planning on giving Software Engineering interviews, and want to get a quick crash course on System Design before the real interview.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default OverviewPart;