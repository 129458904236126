import React from 'react';
import { Link } from 'react-router-dom';
import previewImage from '../../../../assets/img/courses/adults/data_structure.jpeg'

const OverviewPart = () => {

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>Cracking Coding Interview : Data Structure & Algorithm</h4>
                    <p>Course is specially designed for people who wants to get that dream job.</p>
                    <p>Going through most of the useful data structures with explanation.</p>
                    <p>You can expect to follow step-by-step approach to solve any coding question.</p>
                    <p>Teach students the real-life skills needed to get those in-demand jobs.</p>

                    <img src={previewImage} className='pt-20 pb-20'/>
                    <h3>What you’ll learn?</h3>
                    <ul className="review-list adults-style">
                        <li>Have an understanding of how to systematically approach coding problems in a step by step manner</li>
                        <li>Solve Easy to Medium Difficulty problems that address different data structures</li>
                        <li>Use the right Data Structures and Algorithms to pass coding interview questions that have the optimal solution</li>
                        <li>The ultimate resource to prepare for coding interviews, tech interviews and programming interviews</li>
                        <li>Ace the coding interview at the top tech companies (Google, Amazon, Facebook, Apple, Microsoft, Uber, Deliveroo, PayPal + others)</li>
                    </ul>

                    <br/>

                    <h3>Requirements</h3>
                    <ul className="review-list adults-style">
                        <li>Basic Knowledge of any one programming language like Java/Python/JS/Swift/Kotlin...</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default OverviewPart;