
import SingleServiceThree from '../../components/Service/SingleServiceThree';

import serviceImg1 from '../../assets/img/services/home7/bg/1.png';
import serviceImg2 from '../../assets/img/services/home7/bg/2.png';
import serviceImg3 from '../../assets/img/services/home7/bg/3.png';
import serviceImg4 from '../../assets/img/services/home7/bg/4.png';

// import serviceIcon1 from '../../assets/img/services/home7/icon/1.png';
// import serviceIcon2 from '../../assets/img/services/home7/icon/2.png';
// import serviceIcon3 from '../../assets/img/services/home7/icon/3.png';
// import serviceIcon4 from '../../assets/img/services/home7/icon/4.png';

const Service = () => {

    return (
        <div className="rs-services style7 pb-100 md-pt-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 md-mb-30">
                        <SingleServiceThree
                            serviceImg={serviceImg1}
                            // serviceIcon={serviceIcon1}
                            Title='Certificate courses'
                            Desc='As a TQUK approved center to delivery qualifications to our students.
                            Certificates will be issued to students who are qualified.
                            '
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 md-mb-30">
                        <SingleServiceThree
                            serviceImg={serviceImg2}
                            // serviceIcon={serviceIcon2}
                            Title='Fun with code for kids'
                            Desc='Early childhood education promotes cognitive and social development, 
                            and allows kids to enjoy freedom to innovate and enact new possibilities.
                            '
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 md-mb-30">
                        <SingleServiceThree
                            serviceImg={serviceImg3}
                            // serviceIcon={serviceIcon3}
                            Title='Develop your career'
                            Desc='Career development is a lifelong process of learning skills and exploring new options.
                            It is the best way to find the work you are passionate about.'
                        />
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <SingleServiceThree
                            serviceImg={serviceImg4}
                            // serviceIcon={serviceIcon4}
                            Title='Build your online business'
                            Desc='Fast setup and develop your digital products in different platforms without technical background to drive the online revenue.'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Service;