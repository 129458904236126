import React from 'react';
import { Link } from 'react-router-dom';
import previewImage from '../../../../assets/img/courses/adults/android_studio_kotlin.png'

const OverviewPart = () => {

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>The Complete Android & Kotlin Development Masterclass</h4>
                    <p>Do you have no programming experience whatsoever? Or maybe you have worked with another programming language in the past?</p>
                    <p>You have a great idea for an app and you just need to learn how to make it happen? Or your friends know that you are tech-savvy and they constantly come to you with their great ideas and want you to build them?</p>
                    <p>In this course, you will learn everything you need to know, to build your own apps, no matter what you need them for. For your company, your friends, your own business, or simply for fun. You'll become a real Android App Developer by getting the best Android App development course.</p>

                    <img src={previewImage} className='pt-20 pb-20'/>
                    <h3>What you’ll learn?</h3>
                    <ul className="review-list adults-style">
                        <li>You will be very confident using Kotlin and programming in general</li>    
                        <li>You'll be able to develop cloud apps using Google Firebase</li>
                        <li>Start your own app based business</li>
                        <li>You'll be able to work as an Android freelancer and work from anywere in the world</li>
                        <li>Become a full time Android developer</li>
                    </ul>

                    <br/>

                    <h3>Requirements</h3>
                    <ul className="review-list adults-style">
                        <li>No programming experience needed - We'll teach you everything you need to know</li>    
                        <li>A Windows, Mac or Linux Computer</li>
                        <li>We'll walk you through, step-by-step how to get Android Studio installed and set up</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default OverviewPart;