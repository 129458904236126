import React from 'react';
import Header from '../../../components/Layout/Header/Header';
import FooterStyleTwo from '../../../components/Layout/Footer/FooterStyleTwo';
import OffWrap from '../../../components/Layout/Header/OffWrap';
import SearchModal from '../../../components/Layout/Header/SearchModal';
import CourseDetailsKids1000 from '../kids/1000/CourseDetailsMain';
import CourseDetailsKids1001 from '../kids/1001/CourseDetailsMain';
import CourseDetailsAdults2000 from '../adults/2000/CourseDetailsMain';
import CourseDetailsAdults2001 from '../adults/2001/CourseDetailsMain';
import CourseDetailsAdults2002 from '../adults/2002/CourseDetailsMain';
import CourseDetailsAdults2003 from '../adults/2003/CourseDetailsMain';

// Image
import stickyLogo from '../../../assets/img/logo/logo.png';
import darkLogo from '../../../assets/img/logo/logo-dark2.png';

const CourseSingle = (props) => {

    function CourseDetail(props) {
        const {type, id} = props.match.params;
        // if (type === "kids") {
        switch (id) {
            case "1000":
                return <CourseDetailsKids1000/>
            case "1001":
                return <CourseDetailsKids1001/>
            case "2000":
                return <CourseDetailsAdults2000/>
            case "2001":
                return <CourseDetailsAdults2001/>
            case "2002":
                return <CourseDetailsAdults2002/>
            case "2003":
                return <CourseDetailsAdults2003/>

        }
        // } else if (type === "adults") {
    
        // }
    }

    return (
        <React.Fragment>
            <OffWrap />

            <Header
                parentMenu='course'
                headerNormalLogo={darkLogo}
                headerStickyLogo={stickyLogo}
                CanvasLogo={darkLogo}
                mobileNormalLogo={darkLogo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style3 modify expand-none"
                TopBarClass="topbar-area dark-parimary-bg"
                TopBar='enable'
                emailAddress='info@proclasshub.com'
                phoneNumber='+852 6412 3544'
            />

            {/* breadcrumb-area-start */}
            {/* <SiteBreadcrumb
                pageTitle="Learn User Interface and User Experience"
                pageName="Course Details"
                breadcrumbsImg={bannerbg}
            /> */}
            {/* breadcrumb-area-start */}

            {/* Course Details Main */}
            { CourseDetail(props) }
            {/* Course Details Main */}

            <FooterStyleTwo 
                footerTopClass='footer-top no-gap'
            />
            <SearchModal />
        </React.Fragment>
    );
}

export default CourseSingle;