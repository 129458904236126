import React from 'react';
import { Link } from 'react-router-dom';
import previewImage from '../../../../assets/img/courses/adults/xcode_swift.jpeg'

const OverviewPart = () => {

    return (
        <div className="content white-bg pt-30">
            <div className="course-overview">
                <div className="inner-box">
                    <h4>iOS & Swift - The Complete iOS App Development Bootcamp</h4>
                    <p>From Beginner to iOS App Developer with Just One Course!</p>

                    <p>In our course, we teach how to code using Swift 5.1 and build beautiful iOS 15+ apps for iPhone and iPad. Even if you have ZERO programming experience.</p>
                    <p>We'll take you step-by-step through and teach you everything you need to know to succeed as an iOS app developer.</p>
                    <p>The curriculum has been completely revamped for iOS 15+ and Xcode 13. Including comprehensive modules on Apple's latest technology - iOS, iPadOS and macOS app interface design.</p>
                    <p>By the end of this course, you will be fluently programming in Swift 5.1 and be ready to make your own apps or start a freelancing job as an iOS developer.</p>

                    <img src={previewImage} className='pt-20 pb-20'/>
                    <h3>What you’ll learn?</h3>
                    <ul className="review-list adults-style">
                        <li>You will learn Xcode, Swift, UIKit, Cocoapods and Realm.</li>    
                        <li>You will learn by doing, where every lesson is incorporated into a real-world app project.</li>
                        <li>Start your own app based business</li>
                        <li>Become a digital nomad by working as a freelance iOS developer</li>
                        <li>Become a full time iOS developer</li>
                    </ul>

                    <br/>

                    <h3>Requirements</h3>
                    <ul className="review-list adults-style">
                        <li>No programming experience needed - We'll teach you everything you need to know</li>    
                        <li>A Mac computer running macOS 12.2 (Monterey) or a PC running macOS.</li>
                        <li>No paid software required - all apps will be created in Xcode 13</li>
                        <li>We'll walk you through, step-by-step how to get Xcode installed and set up</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default OverviewPart;