import React from 'react';

import Header from '../../components/Layout/Header/Header';
import FooterStyleTwo from '../../components/Layout/Footer/FooterStyleTwo';
// import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
// import Newsletter from '../../components/Common/Newsletter';
// import SiteBreadcrumb from '../../components/Common/Breadcumb';
import CourseMain from './CourseMain';

// Image
import stickyLogo from '../../assets/img/logo/logo.png';
import darkLogo from '../../assets/img/logo/logo-dark2.png';
// import footerLogo from '../../assets/img/logo/lite-logo.png';

// import bannerbg from '../../assets/img/breadcrumbs/2.jpg';

const CourseTwo = (props) => {
    return (
        <React.Fragment>
            <OffWrap />
            <Header
                parentMenu='course'
                headerNormalLogo={darkLogo}
                headerStickyLogo={stickyLogo}
                CanvasLogo={darkLogo}
                mobileNormalLogo={darkLogo}
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style3 modify expand-none"
                TopBarClass="topbar-area dark-parimary-bg"
                TopBar='enable'
                emailAddress='info@proclasshub.com'
                phoneNumber='+852 6412 3544'
            />

            {/* breadcrumb-area-start */}
            {/* <SiteBreadcrumb
                pageTitle="Course Grid 02"
                pageName="Course"
                breadcrumbsImg={bannerbg}
            /> */}
            {/* breadcrumb-area-start */}

            {/* Course Main */}
            <CourseMain type={props.match.params.type}/>
            {/* Course Main */}

            <FooterStyleTwo 
                footerTopClass='footer-top no-gap'
            />
            <SearchModal />
        </React.Fragment>
    );
}

export default CourseTwo;